import { Deal, DealProperty } from "@elphi/types";
import { MofinProperty } from "@elphi/types/entities/mofin/property";
import {
  hideFixNFlip,
  hideFixNFlipNoBridge,
  hideRental30,
  hideRental30OrEmpty,
  isEmptyLoanProgramType,
  isFixNFlip,
  isNewConstruction
} from "../../../../components/application/sections/sections.utils";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import {
  allocatedLoanAmount,
  annualHomeownersAssociationFeeAmount,
  annualTaxAmount,
  appraisalEffectiveDate,
  appraisalForm1007RequiredIndicator,
  appraisalManagementCompanyName,
  appraisalManagementContactPointEmailValue,
  appraisalManagementContactPointTelephoneValue,
  appraisalManagementRepresentativeFullName,
  appraisalOrderedDate,
  appraisalType,
  appraisalValueDerivationType,
  borrowerRenovationCostsAfterPurchaseAmount,
  calculatedAllocatedLoanAmount,
  conditionRatingType,
  constructionBudgetAssessment,
  constructionCostAmount,
  constructionCostComments,
  constructionHoldbackAmount,
  floodInsurancePremiumOutstandingAmount,
  groundLeaseExpirationDate,
  leaseStatusType,
  leaseStrategy,
  ltcRatePercent,
  originalPurchaseDate,
  originalPurchasePriceAmount,
  outstandingLoanPayoffAmount,
  payoffExpirationDate,
  pricingEngineLTARVRatePercent,
  propertyAccessInformation,
  propertyAreaValue,
  propertyCountyName,
  propertyInsurancePremiumOutstandingAmount,
  propertyLoanPurpose,
  propertyStructureBuiltYear,
  propertyValuationAmount,
  propertyZoningComplianceRatingType,
  purchasePriceAmount,
  rentalLTVPercent,
  specificZoningClassification,
  subjectToPropertyValuationAmount,
  totalBathroomCount,
  totalBedroomCount,
  totalCostAmount
} from "../../base/inputs/property.inputs";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  canBeRebuiltIndicator,
  initialFundedAmount,
  payoffInterestDays
} from "../inputs/property.inputs";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";
import { MofinSections } from "../types";

const propertyDetailSection = (r: {
  state: Partial<MofinProperty>;
  dealState?: Deal;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const dealState = r.dealState || {};
  return buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Property Details",
    inputs: [
      propertyLoanPurpose,
      purchasePriceAmount,
      outstandingLoanPayoffAmount,
      calculatedAllocatedLoanAmount,
      addRuleToInput({
        input: rentalLTVPercent,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: pricingEngineLTARVRatePercent,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlipNoBridge(dealState)
          }
        ]
      }),
      ...addRulesToInputs({
        inputs: [
          constructionCostAmount,
          constructionCostComments,
          constructionHoldbackAmount
        ],
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlip(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: leaseStatusType,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      originalPurchaseDate,
      originalPurchasePriceAmount,
      borrowerRenovationCostsAfterPurchaseAmount,
      ltcRatePercent,
      ...addRulesToInputs({
        inputs: [annualTaxAmount, annualHomeownersAssociationFeeAmount],
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),

      totalCostAmount,
      addRuleToInput({
        input: leaseStrategy,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: constructionBudgetAssessment,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () =>
              !isNewConstruction(dealState) &&
              !isFixNFlip(dealState) &&
              !isEmptyLoanProgramType(dealState)
          }
        ]
      }),
      allocatedLoanAmount,
      groundLeaseExpirationDate,
      propertyInsurancePremiumOutstandingAmount,
      floodInsurancePremiumOutstandingAmount,
      addRuleToInput({
        input: initialFundedAmount,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlipNoBridge(dealState)
          }
        ]
      })
    ],
    fieldSpecs: mofinSpecs.property.entitySpecs
  });
};

const valuationSection = (r: {
  state: Partial<MofinProperty>;
  dealState?: Deal;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const dealState = r.dealState || {};
  return buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Valuation",
    inputs: [
      appraisalValueDerivationType,
      propertyValuationAmount,
      addRuleToInput({
        input: subjectToPropertyValuationAmount,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => !hideRental30OrEmpty(dealState)
          }
        ]
      }),
      appraisalOrderedDate,
      appraisalEffectiveDate,
      appraisalType,
      conditionRatingType,
      propertyAreaValue,
      totalBedroomCount,
      totalBathroomCount,
      propertyStructureBuiltYear,
      propertyCountyName,
      specificZoningClassification,
      propertyZoningComplianceRatingType,
      addRuleToInput({
        input: appraisalForm1007RequiredIndicator,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      appraisalManagementRepresentativeFullName,
      appraisalManagementContactPointTelephoneValue,
      appraisalManagementContactPointEmailValue,
      appraisalManagementCompanyName,
      propertyAccessInformation,
      addRuleToInput({
        input: canBeRebuiltIndicator,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlip(dealState)
          }
        ]
      })
    ],
    fieldSpecs: mofinSpecs.property.entitySpecs
  });
};

const payoffSection = (r: {
  state: Partial<DealProperty>;
  onChange?: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Payoff",
    inputs: [payoffExpirationDate, payoffInterestDays],
    fieldSpecs: mofinSpecs.property.entitySpecs
  });

const generalSection = (r: {
  state: Partial<DealProperty>;
  onChange?: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "General",
    inputs: [],
    fieldSpecs: mofinSpecs.property.entitySpecs
  });

export const propertySectionBuilders: MofinSections["property"] = {
  payoffSection,
  propertyDetailSection,
  valuationSection,
  generalSection
};
