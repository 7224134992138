import {
  ARMRepriceFrequencyType,
  AchExemptionStatusType,
  AggregationFocusType,
  AmortizationType,
  DebtStatusType,
  DeepPartial,
  ExceptionPolicy,
  ExecutionType,
  FieldType,
  FixNFlipNBridgePlusTierType,
  FundingShieldStatusType,
  LoanProgramType,
  PrepaymentPenaltyType,
  PropertyManagerType,
  QuoteType,
  RecourseType
} from "@elphi/types";
import {
  limaChannelType,
  limaDealExceptionApprovedBy,
  limaDealOperationsDepartmentType,
  limaFloodInsurancePremiumPayment,
  limaLenderIdentifierType,
  limaLoanPayment,
  limaLoanProductType,
  limaLoanTermPeriodMonthCount,
  limaPaymentDueDay,
  limaWarehouseBank
} from "@elphi/types/entities/lima";
import {
  BaseDealFieldsOmit,
  LimaDealFields
} from "@elphi/types/entities/lima/deal/limaDeal.types";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  booleanOptions,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";
import {
  BaseSchemaDealFieldSpecs,
  baseSchemaDealSpecs
} from "../../../base/spec-files/deal/deal.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { BaseSpec } from "../../../types";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";

type LimaSchemaDealFieldSpecs = EntityFormFieldSpecs<LimaDealFields>;

export type FullLimaDealFieldSpecs = Partial<BaseSchemaDealFieldSpecs> &
  LimaSchemaDealFieldSpecs;

const limaDealFieldSpecs: BaseSpec<LimaSchemaDealFieldSpecs> = {
  ...baseEntityFormFieldSpecs,
  aggregations: {
    TotalBrokerFeeAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Total Broker Fee",
        fieldKey: ["aggregations", "TotalBrokerFeeAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Total Broker Fee",
        fieldKey: ["aggregations", "TotalBrokerFeeAmount", "override"]
      },
      focused: {
        fieldKey: ["aggregations", "TotalBrokerFeeAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ARMRepriceFrequencyType: {
      calculated: {
        fieldKey: ["aggregations", "ARMRepriceFrequencyType", "calculated"],
        fieldType: FieldType.SingleSelect,
        label: "ARM Reprice Frequency",
        options: createOptionsFromMap(ARMRepriceFrequencyType)
      },
      override: {
        fieldKey: ["aggregations", "ARMRepriceFrequencyType", "override"],
        fieldType: FieldType.SingleSelect,
        label: "ARM Reprice Frequency",
        options: createOptionsFromMap(ARMRepriceFrequencyType)
      },
      focused: {
        fieldKey: ["aggregations", "ARMRepriceFrequencyType", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAnnualCapitalExpenditure: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalAnnualCapitalExpenditure",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Annual Capital Expenditure"
      },
      override: {
        fieldKey: ["aggregations", "TotalAnnualCapitalExpenditure", "override"],
        fieldType: FieldType.Money,
        label: "Total Annual Capital Expenditure"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAnnualCapitalExpenditure", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyPaymentOfCapitalExpenditure: {
      calculated: {
        fieldKey: [
          "aggregations",
          "MonthlyPaymentOfCapitalExpenditure",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Monthly Payment of Capital Expenditure"
      },
      override: {
        fieldKey: [
          "aggregations",
          "MonthlyPaymentOfCapitalExpenditure",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Monthly Payment of Capital Expenditure"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "MonthlyPaymentOfCapitalExpenditure",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  quote: {
    quoteType: {
      fieldKey: ["quote", "quoteType"],
      label: "Quote Type",
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(QuoteType)
    },
    aggregations: {
      QuoteId: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "QuoteId", "thirdParty"],
          label: "Quote ID",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "QuoteId", "override"],
          label: "Quote ID",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "QuoteId", "focused"],
          label: "Quote ID",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      AchRequiredIndicator: {
        thirdParty: {
          fieldType: FieldType.Boolean,
          label: "ACH Required?",
          fieldKey: [
            "quote",
            "aggregations",
            "AchRequiredIndicator",
            "thirdParty"
          ],
          options: booleanOptions
        },
        override: {
          fieldType: FieldType.Boolean,
          label: "ACH Required?",
          fieldKey: [
            "quote",
            "aggregations",
            "AchRequiredIndicator",
            "override"
          ],
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "AchRequiredIndicator",
            "focused"
          ],
          label: "ACH Required?",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      AchExemptionStatus: {
        thirdParty: {
          fieldType: FieldType.Boolean,
          label: "ACH Exemption Status",
          fieldKey: [
            "quote",
            "aggregations",
            "AchExemptionStatus",
            "thirdParty"
          ],
          options: createOptionsFromMap(AchExemptionStatusType)
        },
        override: {
          fieldType: FieldType.Boolean,
          label: "ACH Exemption Status",
          fieldKey: ["quote", "aggregations", "AchExemptionStatus", "override"],
          options: createOptionsFromMap(AchExemptionStatusType)
        },
        focused: {
          fieldKey: ["quote", "aggregations", "AchExemptionStatus", "focused"],
          label: "ACH Exemption Status",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalLoanAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Pricing Engine Total Loan Amount",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Pricing Engine Total Loan Amount",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanAmount",
            "focused"
          ],
          label: "Pricing Engine Total Loan Amount",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OriginationFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "OriginationFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "OriginationFeeAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginationFeeAmount",
            "focused"
          ],
          label: "Origination Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ProcessingFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "ProcessingFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Processing Fee",
          fieldKey: ["quote", "aggregations", "ProcessingFeeAmount", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ProcessingFeeAmount", "focused"],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BrokerOriginationFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Broker Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerOriginationFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Broker Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerOriginationFeeAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerOriginationFeeAmount",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ServicingSetupFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Servicing Setup Fee",
          fieldKey: ["quote", "aggregations", "ServicingSetupFee", "thirdParty"]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Servicing Setup Fee",
          fieldKey: ["quote", "aggregations", "ServicingSetupFee", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ServicingSetupFee", "focused"],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceProcessingFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceProcessingFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceProcessingFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceProcessingFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceApplicationFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Application Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceApplicationFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Application Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceApplicationFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceApplicationFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LimaRateToBorrowerPercent: {
        thirdParty: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Interest Rate Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaRateToBorrowerPercent",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Interest Rate Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaRateToBorrowerPercent",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LimaRateToBorrowerPercent",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LimaOriginationFeeToBorrowerPercent: {
        thirdParty: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Origination Fee Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaOriginationFeeToBorrowerPercent",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Origination Fee Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaOriginationFeeToBorrowerPercent",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LimaOriginationFeeToBorrowerPercent",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceDocumentFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Document Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceDocumentFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Document Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceDocumentFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceDocumentFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceAppraisalFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Appraisal Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceAppraisalFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Appraisal Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceAppraisalFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceAppraisalFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineWarnings: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Warnings",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineWarnings",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Warnings",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineWarnings",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineWarnings",
            "focused"
          ],
          label: "Pricing Engine Warnings",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BrokerProcessingFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Broker Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerProcessingFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Broker Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerProcessingFeeAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerProcessingFeeAmount",
            "focused"
          ],
          label: "Broker Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      FeeLLPAs: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Fee LLPAs",
          fieldKey: ["quote", "aggregations", "FeeLLPAs", "thirdParty"]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Fee LLPAs",
          fieldKey: ["quote", "aggregations", "FeeLLPAs", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "FeeLLPAs", "focused"],
          label: "Fee LLPAs",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      RateLLPAs: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Rate LLPAs",
          fieldKey: ["quote", "aggregations", "RateLLPAs", "thirdParty"]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Rate LLPAs",
          fieldKey: ["quote", "aggregations", "RateLLPAs", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "RateLLPAs", "focused"],
          label: "Rate LLPAs",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineOverrides: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Overrides",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineOverrides",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Overrides",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineOverrides",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineOverrides",
            "focused"
          ],
          label: "Pricing Engine Overrides",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ValidationErrors: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Validation Errors",
          fieldKey: ["quote", "aggregations", "ValidationErrors", "thirdParty"]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Validation Errors",
          fieldKey: ["quote", "aggregations", "ValidationErrors", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ValidationErrors", "focused"],
          label: "Pricing Engine Validation Errors",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },

      LtvEligibility: {
        thirdParty: {
          fieldType: FieldType.String,
          label: "LTV Eligibility",
          fieldKey: ["quote", "aggregations", "LtvEligibility", "thirdParty"]
        },
        override: {
          fieldType: FieldType.String,
          label: "LTV Eligibility",
          fieldKey: ["quote", "aggregations", "LtvEligibility", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "LtvEligibility", "focused"],
          label: "LTV Eligibility",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      InterestReserveEscrow: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Interest Reserve Escrow",
          fieldKey: [
            "quote",
            "aggregations",
            "InterestReserveEscrow",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Interest Reserve Escrow",
          fieldKey: [
            "quote",
            "aggregations",
            "InterestReserveEscrow",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "InterestReserveEscrow",
            "focused"
          ],
          label: "Interest Reserve Amount",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PrepaymentPenaltyType: {
        thirdParty: {
          fieldType: FieldType.SingleSelect,
          label: "Prepayment Penalty",
          fieldKey: [
            "quote",
            "aggregations",
            "PrepaymentPenaltyType",
            "thirdParty"
          ],
          options: createOptionsFromMap(PrepaymentPenaltyType)
        },
        override: {
          fieldType: FieldType.SingleSelect,
          label: "Prepayment Penalty",
          fieldKey: [
            "quote",
            "aggregations",
            "PrepaymentPenaltyType",
            "override"
          ],
          options: createOptionsFromMap(PrepaymentPenaltyType)
        },
        focused: {
          label: "Prepayment Penalty",
          fieldKey: [
            "quote",
            "aggregations",
            "PrepaymentPenaltyType",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      NoteRatePercent: {
        thirdParty: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Note Rate",
          fieldKey: ["quote", "aggregations", "NoteRatePercent", "thirdParty"]
        },
        override: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Note Rate",
          fieldKey: ["quote", "aggregations", "NoteRatePercent", "override"]
        },
        focused: {
          label: "Note Rate",
          fieldKey: ["quote", "aggregations", "NoteRatePercent", "focused"],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      MonthlyPrincipalInterestPaymentAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Monthly Principal & Interest Payment",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyPrincipalInterestPaymentAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Monthly Principal & Interest Payment",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyPrincipalInterestPaymentAmount",
            "override"
          ]
        },
        focused: {
          label: "",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyPrincipalInterestPaymentAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      MonthlyTaxInsurancePaymentAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of Taxes & Insurance",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyTaxInsurancePaymentAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of Taxes & Insurance",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyTaxInsurancePaymentAmount",
            "override"
          ]
        },
        focused: {
          label: "Monthly Payment of Taxes & Insurance",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyTaxInsurancePaymentAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      MonthlyHomeownersAssociationAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of HOA Dues to HOA",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyHomeownersAssociationAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of HOA Dues to HOA",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyHomeownersAssociationAmount",
            "override"
          ]
        },
        focused: {
          label: "Monthly Payment of HOA Dues to HOA",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyHomeownersAssociationAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      TotalMonthlyPITIPaymentAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Total Monthly Payment (PITI)",
          fieldKey: [
            "quote",
            "aggregations",
            "TotalMonthlyPITIPaymentAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Total Monthly Payment (PITI)",
          fieldKey: [
            "quote",
            "aggregations",
            "TotalMonthlyPITIPaymentAmount",
            "override"
          ]
        },
        focused: {
          label: "Total Monthly Payment (PITI)",
          fieldKey: [
            "quote",
            "aggregations",
            "TotalMonthlyPITIPaymentAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      DebtServiceCoverageRatioPercent: {
        thirdParty: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Total Rent to Debt Service Coverage Ratio",
          fieldKey: [
            "quote",
            "aggregations",
            "DebtServiceCoverageRatioPercent",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Total Rent to Debt Service Coverage Ratio",
          fieldKey: [
            "quote",
            "aggregations",
            "DebtServiceCoverageRatioPercent",
            "override"
          ]
        },
        focused: {
          label: "Total Rent to Debt Service Coverage Ratio",
          fieldKey: [
            "quote",
            "aggregations",
            "DebtServiceCoverageRatioPercent",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BuyDownFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Buy Down Fee",
          fieldKey: ["quote", "aggregations", "BuyDownFeeAmount", "thirdParty"]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Buy Down Fee",
          fieldKey: ["quote", "aggregations", "BuyDownFeeAmount", "override"]
        },
        focused: {
          label: "N/A",
          fieldKey: ["quote", "aggregations", "BuyDownFeeAmount", "focused"],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BuyUpFeeReductionAmount: {
        thirdParty: {
          fieldType: FieldType.SignedMoney,
          label: "Buy Up Fee Reduction",
          fieldKey: [
            "quote",
            "aggregations",
            "BuyUpFeeReductionAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.SignedMoney,
          label: "Buy Up Fee Reduction",
          fieldKey: [
            "quote",
            "aggregations",
            "BuyUpFeeReductionAmount",
            "override"
          ]
        },
        focused: {
          label: "N/A",
          fieldKey: [
            "quote",
            "aggregations",
            "BuyUpFeeReductionAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },

      UnderPropertyValuationAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PrimaryBorrowerCreditScore: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Number
        },

        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      StateNY: {
        calculated: {
          fieldKey: ["quote", "aggregations", "StateNY", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "StateNY", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "StateNY", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      InternalL1CRefinanceIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "InternalL1CRefinanceIndicator",
            "calculated"
          ],
          label: "Is L1C Refinance?",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "InternalL1CRefinanceIndicator",
            "override"
          ],
          label: "Is L1C Refinance?",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "InternalL1CRefinanceIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BorrowingEntity: {
        calculated: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OverPropertyValuationAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      UnderPropertyValuationTotalCost: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationTotalCost",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationTotalCost",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationTotalCost",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      TotalValuationOrPurchasePriceAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "calculated"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "override"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "focused"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyTypeCondoIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeCondoIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeCondoIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeCondoIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyValuationAmountUnderIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyValuationAmountUnderIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyValuationAmountUnderIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyValuationAmountUnderIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      USCitizenshipIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      SeasonalRental: {
        calculated: {
          fieldKey: ["quote", "aggregations", "SeasonalRental", "calculated"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: ["quote", "aggregations", "SeasonalRental", "override"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: ["quote", "aggregations", "SeasonalRental", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyTypeMultifamilyIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeMultifamilyIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeMultifamilyIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeMultifamilyIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      InitialMonthlyInterestOnlyPaymentAmount: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "InitialMonthlyInterestOnlyPaymentAmount",
            "thirdParty"
          ],
          label: "Initial Monthly Interest Only Payment",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "InitialMonthlyInterestOnlyPaymentAmount",
            "override"
          ],
          label: "Initial Monthly Interest Only Payment",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "InitialMonthlyInterestOnlyPaymentAmount",
            "focused"
          ],
          label: "Initial Monthly Interest Only Payment",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineLTARVRatePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineLTARVRatePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineLTARVRatePercent",
            "override"
          ],
          label: "Pricing Engine Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineLTARVRatePercent",
            "focused"
          ],
          label: "Pricing Engine Loan to After Repair Value",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      FullyDrawnInterestOnlyPayment: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "FullyDrawnInterestOnlyPayment",
            "thirdParty"
          ],
          label: "Fully Drawn Interest Only Payment",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "FullyDrawnInterestOnlyPayment",
            "override"
          ],
          label: "Fully Drawn Interest Only Payment",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "FullyDrawnInterestOnlyPayment",
            "focused"
          ],
          label: "Fully Drawn Interest Only Payment",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },

      PrimaryGuarantor: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      CompletedExits: {
        calculated: {
          fieldKey: ["quote", "aggregations", "CompletedExits", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "CompletedExits", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "CompletedExits", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      SumTotalStatementQualifyingBalance: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      AltTier: {
        calculated: {
          fieldKey: ["quote", "aggregations", "AltTier", "calculated"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: ["quote", "aggregations", "AltTier", "override"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: ["quote", "aggregations", "AltTier", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LesserOfLotOrPurchase: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "calculated"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "override"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "focused"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      DebtStatus: {
        calculated: {
          fieldKey: ["quote", "aggregations", "DebtStatus", "calculated"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromMap(DebtStatusType)
        },
        override: {
          fieldKey: ["quote", "aggregations", "DebtStatus", "override"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromMap(DebtStatusType)
        },
        focused: {
          fieldKey: ["quote", "aggregations", "DebtStatus", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyAddressLineText: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyCityName: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyStateCode: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyStateCode",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyStateCode", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyStateCode", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyPostalCode: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyPostalCode",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyPostalCode", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyPostalCode", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyType: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PropertyType", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyType", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyType", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OriginalPurchaseDate: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Date
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Date
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BorrowerTier: {
        calculated: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "calculated"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromMap(FixNFlipNBridgePlusTierType)
        },
        override: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "override"],
          label: "N/A",
          fieldType: FieldType.String,
          options: createOptionsFromMap(FixNFlipNBridgePlusTierType)
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalLoanToAfterRepairValueOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanToAfterRepairValueOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Total Loan to After Repair Value Override (%)",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanToAfterRepairValueOverridePercent",
            "override"
          ],
          label: "Pricing Engine Total Loan to After Repair Value Override (%)",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanToAfterRepairValueOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Total Loan to After Repair Value Override (%)",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalBlendedLoanToCostOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalBlendedLoanToCostOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Total Blended Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalBlendedLoanToCostOverridePercent",
            "override"
          ],
          label: "Pricing Engine Total Blended Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalBlendedLoanToCostOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Total Blended Loan to Cost Override (%)",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalPurchaseLoanToCostOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalPurchaseLoanToCostOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Total Purchase Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalPurchaseLoanToCostOverridePercent",
            "override"
          ],
          label: "Pricing Engine Total Purchase Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalPurchaseLoanToCostOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Total Purchase Loan to Cost Override (%)",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineNoteRateOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineNoteRateOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Note Rate Override",
          fieldType: FieldType.PercentageThreeDecimal
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineNoteRateOverridePercent",
            "override"
          ],
          label: "Pricing Engine Note Rate Override",
          fieldType: FieldType.PercentageThreeDecimal
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineNoteRateOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Note Rate Override",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineMaxLoanToAfterRepairValuePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxLoanToAfterRepairValuePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Max Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxLoanToAfterRepairValuePercent",
            "override"
          ],
          label: "Pricing Engine Max Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxLoanToAfterRepairValuePercent",
            "focused"
          ],
          label: "Pricing Engine Max Loan to After Repair Value",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineMaxBlendedLoanToCostPercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxBlendedLoanToCostPercent",
            "thirdParty"
          ],
          label: "Pricing Engine Max Blended Loan to Cost",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxBlendedLoanToCostPercent",
            "override"
          ],
          label: "Pricing Engine Max Blended Loan to Cost",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxBlendedLoanToCostPercent",
            "focused"
          ],
          label: "Pricing Engine Max Blended Loan to Cost",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineMaxPurchaseLoanToCostPercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxPurchaseLoanToCostPercent",
            "thirdParty"
          ],
          label: "Pricing Engine Max Purchase Loan to Cost",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxPurchaseLoanToCostPercent",
            "override"
          ],
          label: "Pricing Engine Max Purchase Loan to Cost",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxPurchaseLoanToCostPercent",
            "focused"
          ],
          label: "Pricing Engine Max Purchase Loan to Cost",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      Margin: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "Margin", "thirdParty"],
          label: "Margin",
          fieldType: FieldType.PercentageThreeDecimal
        },
        override: {
          fieldKey: ["quote", "aggregations", "Margin", "override"],
          label: "Margin",
          fieldType: FieldType.PercentageThreeDecimal
        },
        focused: {
          fieldKey: ["quote", "aggregations", "Margin", "focused"],
          label: "Margin",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      FloorPercent: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "FloorPercent", "thirdParty"],
          label: "Floor",
          fieldType: FieldType.PercentageThreeDecimal
        },
        override: {
          fieldKey: ["quote", "aggregations", "FloorPercent", "override"],
          label: "Floor",
          fieldType: FieldType.PercentageThreeDecimal
        },
        focused: {
          fieldKey: ["quote", "aggregations", "FloorPercent", "focused"],
          label: "Floor",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LifetimeCapPercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "LifetimeCapPercent",
            "thirdParty"
          ],
          label: "Lifetime Cap",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: ["quote", "aggregations", "LifetimeCapPercent", "override"],
          label: "Lifetime Cap",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: ["quote", "aggregations", "LifetimeCapPercent", "focused"],
          label: "Lifetime Cap",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      DeferredOriginationFeeAmount: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "DeferredOriginationFeeAmount",
            "thirdParty"
          ],
          label: "Deferred Origination Fee (DO NOT OVERRIDE)",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "DeferredOriginationFeeAmount",
            "override"
          ],
          label: "Deferred Origination Fee (DO NOT OVERRIDE)",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "DeferredOriginationFeeAmount",
            "focused"
          ],
          label: "Deferred Origination Fee (DO NOT OVERRIDE)",
          fieldType: FieldType.Money
        }
      },
      BlendedLTCAdjuster: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "BlendedLTCAdjuster",
            "thirdParty"
          ],
          label: "Blended LTC Adjuster",
          fieldType: FieldType.Integer
        },
        override: {
          fieldKey: ["quote", "aggregations", "BlendedLTCAdjuster", "override"],
          label: "Blended LTC Adjuster",
          fieldType: FieldType.Integer
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BlendedLTCAdjuster", "focused"],
          label: "Blended LTC Adjuster",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PurchaseLTCAdjuster: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PurchaseLTCAdjuster",
            "thirdParty"
          ],
          label: "Purchase LTC Adjuster",
          fieldType: FieldType.Integer
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PurchaseLTCAdjuster",
            "override"
          ],
          label: "Purchase LTC Adjuster",
          fieldType: FieldType.Integer
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PurchaseLTCAdjuster", "focused"],
          label: "Purchase LTC Adjuster",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ARVAdjuster: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "ARVAdjuster", "thirdParty"],
          label: "ARV Adjuster",
          fieldType: FieldType.Integer
        },
        override: {
          fieldKey: ["quote", "aggregations", "ARVAdjuster", "override"],
          label: "ARV Adjuster",
          fieldType: FieldType.Integer
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ARVAdjuster", "focused"],
          label: "ARV Adjuster",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingInfo: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "PricingInfo", "thirdParty"],
          label: "Pricing Information",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PricingInfo", "override"],
          label: "Pricing Information",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PricingInfo", "focused"],
          label: "Pricing Information",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      CampaignCode: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "CampaignCode", "thirdParty"],
          label: "Campaign Code",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "CampaignCode", "override"],
          label: "Campaign Code",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "CampaignCode", "focused"],
          label: "Campaign Code",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      CampaignExpirationDate: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignExpirationDate",
            "thirdParty"
          ],
          label: "Campaign Expiration Date",
          fieldType: FieldType.Date
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignExpirationDate",
            "override"
          ],
          label: "Campaign Expiration Date",
          fieldType: FieldType.Date
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignExpirationDate",
            "focused"
          ],
          label: "Campaign Expiration Date",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      CampaignFeeIncentive: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignFeeIncentive",
            "thirdParty"
          ],
          label: "Campaign Fee Incentive",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignFeeIncentive",
            "override"
          ],
          label: "Campaign Fee Incentive",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignFeeIncentive",
            "focused"
          ],
          label: "Campaign Fee Incentive",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      CampaignLeverageIncentive: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignLeverageIncentive",
            "thirdParty"
          ],
          label: "Campaign Leverage Incentive",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignLeverageIncentive",
            "override"
          ],
          label: "Campaign Leverage Incentive",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignLeverageIncentive",
            "focused"
          ],
          label: "Campaign Leverage Incentive",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      CampaignRateIncentive: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignRateIncentive",
            "thirdParty"
          ],
          label: "Campaign Rate Incentive",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignRateIncentive",
            "override"
          ],
          label: "Campaign Rate Incentive",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "CampaignRateIncentive",
            "focused"
          ],
          label: "Campaign Rate Incentive",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      }
    }
  },
  EmployeeLoanIndicator: {
    fieldType: FieldType.Boolean,
    label: "Lima One - Employee Loan?",
    fieldKey: ["EmployeeLoanIndicator"],
    options: booleanOptions
  },
  RecourseType: {
    fieldType: FieldType.SingleSelect,
    label: "Recourse-Guaranty",
    fieldKey: ["RecourseType"],
    options: createOptionsFromMap(RecourseType)
  },
  AmortizationType: {
    fieldType: FieldType.SingleSelect,
    label: "Note Type",
    fieldKey: ["AmortizationType"],
    options: createOptionsFromMap(AmortizationType)
  },
  ExecutionType: {
    fieldType: FieldType.SingleSelect,
    label: "Loan Execution Type",
    fieldKey: ["ExecutionType"],
    options: createOptionsFromMap(ExecutionType)
  },
  LoanAfterInterestOnlyPeriodMonthCount: {
    fieldType: FieldType.Integer,
    label: "Amortization After IO Period (Months)",
    fieldKey: ["LoanAfterInterestOnlyPeriodMonthCount"]
  },
  FundingShieldStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "FundingShield Status",
    fieldKey: ["FundingShieldStatusType"],
    options: createOptionsFromMap(FundingShieldStatusType)
  },
  PostClosingNotes: {
    fieldType: FieldType.RichText,
    label: "Funding Specialist Notes",
    fieldKey: ["PostClosingNotes"]
  },
  PersonalGuarantorsCount: {
    fieldType: FieldType.Number,
    label: "Number of Personal Guarantors",
    fieldKey: ["PersonalGuarantorsCount"]
  },
  PowerOfAttorneyFullName: {
    fieldType: FieldType.String,
    label: "Power of Attorney",
    fieldKey: ["PowerOfAttorneyFullName"]
  },
  GeneralContractor: {
    FullName: {
      fieldType: FieldType.String,
      label: "General Contractor Company",
      fieldKey: ["GeneralContractor", "FullName"]
    },
    RepresentativeFirstName: {
      fieldType: FieldType.String,
      label: "General Contractor First Name",
      fieldKey: ["GeneralContractor", "RepresentativeFirstName"]
    },
    RepresentativeLastName: {
      fieldType: FieldType.String,
      label: "General Contractor Last Name",
      fieldKey: ["GeneralContractor", "RepresentativeLastName"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "General Contractor Phone Number",
      fieldKey: ["GeneralContractor", "ContactPointTelephoneValue"]
    },
    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "General Contractor Email Address",
      fieldKey: ["GeneralContractor", "ContactPointEmailValue"]
    },
    CapitalExpenditureAmount: {
      fieldType: FieldType.Money,
      label: "Capital Expenditure",
      fieldKey: ["GeneralContractor", "CapitalExpenditureAmount"]
    }
  },
  Broker: {
    FullName: {
      fieldType: FieldType.String,
      label: "Broker Company",
      fieldKey: ["Broker", "FullName"]
    },
    RepresentativeFirstName: {
      fieldType: FieldType.String,
      label: "Broker First Name",
      fieldKey: ["Broker", "RepresentativeFirstName"]
    },
    RepresentativeLastName: {
      fieldType: FieldType.String,
      label: "Broker Last Name",
      fieldKey: ["Broker", "RepresentativeLastName"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Broker Phone Number",
      fieldKey: ["Broker", "ContactPointTelephoneValue"]
    },
    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Broker Email Address",
      fieldKey: ["Broker", "ContactPointEmailValue"]
    },
    NMLSIdentifier: {
      fieldType: FieldType.String,
      label: "Broker NMLS ID",
      fieldKey: ["Broker", "NMLSIdentifier"]
    },
    BrokerWiringInstructionsIndicator: {
      fieldType: FieldType.Boolean,
      label: "Broker Wiring Instructions",
      fieldKey: ["Broker", "BrokerWiringInstructionsIndicator"],
      options: booleanOptions
    }
  },
  PropertyManagement: {
    PropertyManagerType: {
      fieldType: FieldType.SingleSelect,
      label: "How are properties managed?",
      fieldKey: ["PropertyManagement", "PropertyManagerType"],
      options: createOptionsFromMap(PropertyManagerType)
    },
    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Property Management Email Address",
      fieldKey: ["PropertyManagement", "ContactPointEmailValue"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Property Management Phone Number",
      fieldKey: ["PropertyManagement", "ContactPointTelephoneValue"]
    },
    FullAddressName: {
      fieldType: FieldType.String,
      label: "Property Management Full Address",
      fieldKey: ["PropertyManagement", "FullAddressName"]
    },
    FullName: {
      fieldType: FieldType.String,
      label: "Property Management Company",
      fieldKey: ["PropertyManagement", "FullName"]
    },
    PropertyManagementComments: {
      fieldType: FieldType.RichText,
      label: "Property Management Comments",
      fieldKey: ["PropertyManagement", "PropertyManagementComments"]
    },
    RepresentativeFullName: {
      fieldType: FieldType.String,
      label: "Property Management Primary Contact",
      fieldKey: ["PropertyManagement", "RepresentativeFullName"]
    }
  },
  LastPaymentDueDate: {
    fieldType: FieldType.Date,
    label: "Last Payment Due Date",
    fieldKey: ["LastPaymentDueDate"]
  },
  GrossSpreadPercent: {
    fieldType: FieldType.Percentage,
    label: "Gross Spread Percent",
    fieldKey: ["GrossSpreadPercent"]
  },
  LetterOfIntentSignedDate: {
    fieldType: FieldType.Date,
    label: "Letter of Intent Signed Date",
    fieldKey: ["LetterOfIntentSignedDate"]
  },
  ReleasePercent: {
    fieldType: FieldType.Percentage,
    label: "Release Percent",
    fieldKey: ["ReleasePercent"]
  },
  Exceptions: {
    PolicySection: {
      label: "Policy Section",
      fieldKey: ["Exceptions", "PolicySection"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromMap(ExceptionPolicy)
    },
    ExceptionApprovedBy: modifyFromSpec({
      fieldSpecs: baseSchemaDealSpecs.Exceptions.ExceptionApprovedBy,
      changes: {
        options: createOptionsFromMap(limaDealExceptionApprovedBy)
      }
    })
  },
  PromissoryNoteRequestedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Note Requested",
    fieldKey: ["PromissoryNoteRequestedIndicator"],
    options: booleanOptions
  },
  PromissoryNoteShippedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Note Shipped",
    fieldKey: ["PromissoryNoteShippedIndicator"],
    options: booleanOptions
  },
  PromissoryNoteTrackingNumber: {
    fieldType: FieldType.String,
    label: "Note to MFA Tracking Number",
    fieldKey: ["PromissoryNoteTrackingNumber"]
  },
  AllongeAssignmentSentToWarehouseIndicator: {
    fieldType: FieldType.Boolean,
    label: "Allonge and Assignment Sent to Warehouse",
    fieldKey: ["AllongeAssignmentSentToWarehouseIndicator"],
    options: booleanOptions
  },
  AllongeAssignmentTrackingNumber: {
    fieldType: FieldType.String,
    label: "Allonge and Assignment Tracking Number",
    fieldKey: ["AllongeAssignmentTrackingNumber"]
  },
  FundingAndDisbursementApprovalIndicator: {
    fieldType: FieldType.Boolean,
    label: "Funding and Disbursement Approved",
    fieldKey: ["FundingAndDisbursementApprovalIndicator"],
    options: booleanOptions
  },
  LoanPackageChecksReceivedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Checks Received",
    fieldKey: ["LoanPackageChecksReceivedIndicator"],
    options: booleanOptions
  },
  LoanPackageCheckNumber: {
    fieldType: FieldType.String,
    label: "Check Number",
    fieldKey: ["LoanPackageCheckNumber"]
  },
  LoanPackageCheckAmount: {
    fieldType: FieldType.Money,
    label: "Check Amount",
    fieldKey: ["LoanPackageCheckAmount"]
  },
  ProgramDirectorApprovalIndicator: {
    fieldType: FieldType.Boolean,
    label: "Program Director Approval",
    fieldKey: ["ProgramDirectorApprovalIndicator"],
    options: booleanOptions
  },
  ProgramDirectorComments: {
    fieldType: FieldType.RichText,
    label: "Program Director Comments",
    fieldKey: ["ProgramDirectorComments"]
  },
  ChargentTransactionIdentifier: {
    fieldType: FieldType.String,
    label: "Chargent Transaction ID",
    fieldKey: ["ChargentTransactionIdentifier"]
  },
  ChargentGatewayIdentifier: {
    fieldType: FieldType.String,
    label: "Chargent Gateway ID",
    fieldKey: ["ChargentGatewayIdentifier"]
  },
  ChargentCollectionAmount: {
    fieldType: FieldType.Money,
    label: "Chargent Collection Amount",
    fieldKey: ["ChargentCollectionAmount"]
  },
  ValuationFeesCollectedBySalesIndicator: {
    fieldType: FieldType.Boolean,
    label: "Valuation Fees Collected By Sales",
    fieldKey: ["ValuationFeesCollectedBySalesIndicator"],
    options: booleanOptions
  },
  AppraisalNotes: {
    fieldType: FieldType.RichText,
    label: "Appraisal Notes",
    fieldKey: ["AppraisalNotes"]
  },
  CRMId: {
    fieldType: FieldType.String,
    label: "CRM ID",
    fieldKey: ["CRMId"]
  },
  InitialLTVRatePercent: {
    fieldType: FieldType.Percentage,
    label: "Initial Loan to Value",
    fieldKey: ["InitialLTVRatePercent"]
  },
  PropertyInsuranceAgency: {
    FullName: {
      fieldType: FieldType.String,
      label: "Property Insurance Agency",
      fieldKey: ["PropertyInsuranceAgency", "FullName"]
    }
  },
  FloodInsuranceAgency: {
    FullName: {
      fieldType: FieldType.String,
      label: "Flood Insurance Agency",
      fieldKey: ["FloodInsuranceAgency", "FullName"]
    }
  },
  DeferredMaintenanceAmount: {
    fieldType: FieldType.Money,
    label: "Deferred Maintenance",
    fieldKey: ["DeferredMaintenanceAmount"]
  },
  TitleCommitment: {
    AllPayoffsLiensIncludedInSection1RequirementsIndicator: {
      fieldType: FieldType.Boolean,
      label: "Section 1 Requirements Includes All Payoffs and Liens?",
      fieldKey: [
        "TitleCommitment",
        "AllPayoffsLiensIncludedInSection1RequirementsIndicator"
      ],
      options: booleanOptions
    },
    LeaseholdEndorsementIndicator: {
      fieldType: FieldType.Boolean,
      label: "Leasehold Endorsement, if applicable?",
      fieldKey: ["TitleCommitment", "LeaseholdEndorsementIndicator"],
      options: booleanOptions
    },
    RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator: {
      fieldType: FieldType.Boolean,
      label:
        "Section 2 Exceptions Does Not Include Required Payoffs and Liens?",
      fieldKey: [
        "TitleCommitment",
        "RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator"
      ],
      options: booleanOptions
    }
  },
  SettlementStatementMatchLedgerIndicator: {
    fieldType: FieldType.Boolean,
    label: "Settlement Statement Matches Ledger?",
    fieldKey: ["SettlementStatementMatchLedgerIndicator"],
    options: booleanOptions
  },
  LoanAmountVerifiedMatchIndicator: {
    fieldType: FieldType.Boolean,
    label: "Loan Amount Verified Match?",
    fieldKey: ["LoanAmountVerifiedMatchIndicator"],
    options: booleanOptions
  },
  ConstructionBudgetVerifiedMatchIndicator: {
    fieldType: FieldType.Boolean,
    label: "Construction Budget Verified Match?",
    fieldKey: ["ConstructionBudgetVerifiedMatchIndicator"],
    options: booleanOptions
  },
  EONamedInsuredMatchIndicator: {
    fieldType: FieldType.Boolean,
    label: "E&O Named Insured Match?",
    fieldKey: ["EONamedInsuredMatchIndicator"],
    options: booleanOptions
  },
  WireInsuranceMatchDealIndicator: {
    fieldType: FieldType.Boolean,
    label: "Wire Insurance Cert Matches Deal?",
    fieldKey: ["WireInsuranceMatchDealIndicator"],
    options: booleanOptions
  },
  BuildingEnvelopeExpandedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Are you expanding the building envelope to add livable space?",
    fieldKey: ["BuildingEnvelopeExpandedIndicator"],
    options: booleanOptions
  },
  LoadBearingWallsRemoveIntendedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Do you intend to remove two or more load-bearing walls?",
    fieldKey: ["LoadBearingWallsRemoveIntendedIndicator"],
    options: booleanOptions
  },
  SquareFootageAddedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Are you adding livable square footage to the existing structure?",
    fieldKey: ["SquareFootageAddedIndicator"],
    options: booleanOptions
  },
  InterestReserveEscrowOverrideMonthCount: {
    fieldType: FieldType.Integer,
    label: "Interest Reserve Escrow Override (Months)",
    fieldKey: ["InterestReserveEscrowOverrideMonthCount"]
  },
  InterestReserveEscrowOverrideAmount: {
    fieldType: FieldType.Money,
    label: "Interest Reserve Escrow Override (Dollars)",
    fieldKey: ["InterestReserveEscrowOverrideAmount"]
  },
  ProcessingFeeOverride: {
    fieldType: FieldType.Money,
    label: "Processing Fee Override",
    fieldKey: ["ProcessingFeeOverride"]
  },
  LenderFinance: {
    PartnerFirstName: {
      fieldType: FieldType.String,
      label: "Lender Finance Partner First Name",
      fieldKey: ["LenderFinance", "PartnerFirstName"]
    },
    PartnerLastName: {
      fieldType: FieldType.String,
      label: "Lender Finance Partner Last Name",
      fieldKey: ["LenderFinance", "PartnerLastName"]
    },
    PartnerInitialTermSheetApprovalStatusIndicator: {
      fieldType: FieldType.Boolean,
      label: "Initial Term Sheet Approval Status (LF Partner)",
      fieldKey: [
        "LenderFinance",
        "PartnerInitialTermSheetApprovalStatusIndicator"
      ],
      options: booleanOptions
    },
    PartnerInitialTermSheetApprovalStatusDate: {
      fieldType: FieldType.Date,
      label: "Initial Term Sheet Approval Date (LF Partner)",
      fieldKey: ["LenderFinance", "PartnerInitialTermSheetApprovalStatusDate"]
    },
    PartnerFinalTermSheetApprovalStatusDate: {
      fieldType: FieldType.Date,
      label: "Final Term Sheet Approval Date (LF Partner)",
      fieldKey: ["LenderFinance", "PartnerFinalTermSheetApprovalStatusDate"]
    },
    BorrowerFinalTermSheetApprovalStatusDate: {
      fieldType: FieldType.Date,
      label: "Final Term Sheet Approval Date (LF Borrower)",
      fieldKey: ["LenderFinance", "BorrowerFinalTermSheetApprovalStatusDate"]
    }
  },
  ExitStrategy: {
    fieldType: FieldType.String,
    label: "Exit Strategy",
    fieldKey: ["ExitStrategy"]
  },
  TargetCloseDate: {
    fieldType: FieldType.Date,
    label: "Target Close Date",
    fieldKey: ["TargetCloseDate"]
  },
  ApplicationRequestedLoanAmount: {
    fieldType: FieldType.Money,
    label: "Requested Loan Amount",
    fieldKey: ["ApplicationRequestedLoanAmount"]
  },
  ProjectAnticipatedCompletionTimeline: {
    fieldType: FieldType.String,
    label: "Anticipated Completion Timeline",
    fieldKey: ["ProjectAnticipatedCompletionTimeline"]
  },
  ConstructionBudgetRemainingAmount: {
    fieldType: FieldType.Money,
    label: "Total Rehab Budget Remaining",
    fieldKey: ["ConstructionBudgetRemainingAmount"]
  },
  VerifiableConstructionCompletedAmount: {
    fieldType: FieldType.Money,
    label: "Total Verifiable Rehab Completed",
    fieldKey: ["VerifiableConstructionCompletedAmount"]
  },
  LienDollarAmount: {
    fieldType: FieldType.Money,
    label: "Lien Dollar Amount",
    fieldKey: ["LienDollarAmount"]
  },
  PropertyTypeChangingIndicator: {
    fieldType: FieldType.Boolean,
    label: "Will the property type be changing?",
    fieldKey: ["PropertyTypeChangingIndicator"],
    options: booleanOptions
  },
  PropertyZoningChangesIndicator: {
    fieldType: FieldType.Boolean,
    label: "Does the property require any zoning changes?",
    fieldKey: ["PropertyZoningChangesIndicator"],
    options: booleanOptions
  },
  SubdividePropertyPartialReleasesIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Do you intend to subdivide the property or request partial releases?",
    fieldKey: ["SubdividePropertyPartialReleasesIndicator"],
    options: booleanOptions
  },
  LenderTitleProviderIndicator: {
    fieldType: FieldType.Boolean,
    label: "Use Lender's Preferred Title Insurance Provider",
    fieldKey: ["LenderTitleProviderIndicator"],
    options: booleanOptions
  },
  LenderPropertyInsuranceProviderIndicator: {
    fieldType: FieldType.Boolean,
    label: "Use Lender's Preferred Property Insurance Provider",
    fieldKey: ["LenderPropertyInsuranceProviderIndicator"],
    options: booleanOptions
  },
  HOAContactName: {
    fieldType: FieldType.String,
    label: "HOA Contact Name",
    fieldKey: ["HOAContactName"]
  },
  HOAContactPhone: {
    fieldType: FieldType.Phone,
    label: "HOA Contact Phone",
    fieldKey: ["HOAContactPhone"]
  },
  HOAContactEmail: {
    fieldType: FieldType.Email,
    label: "HOA Contact Email",
    fieldKey: ["HOAContactEmail"]
  },
  Occupancy: {
    fieldType: FieldType.Percentage,
    label: "Occupancy %",
    fieldKey: ["Occupancy"]
  },
  PropertyRentReadyConditionIndicator: {
    fieldType: FieldType.Boolean,
    label: "Is the property in rent ready condition?",
    fieldKey: ["PropertyRentReadyConditionIndicator"],
    options: booleanOptions
  },
  PropertyLeasePurchaseOptionIndicator: {
    fieldType: FieldType.Boolean,
    label: "Is the property subject to a lease purchase option?",
    fieldKey: ["PropertyLeasePurchaseOptionIndicator"],
    options: booleanOptions
  },
  OccupationIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Do any sponsors, entity members, or family intend to occupy the property?",
    fieldKey: ["OccupationIndicator"],
    options: booleanOptions
  },
  SellerRelationshipIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "If a purchase, does any loan party have a personal or business relationship with the seller?",
    fieldKey: ["SellerRelationshipIndicator"],
    options: booleanOptions
  },
  AssociatedPropertiesDescription: {
    fieldType: FieldType.RichText,
    label: "List All Associated Properties",
    fieldKey: ["AssociatedPropertiesDescription"]
  },
  DevelopmentStrategy: {
    fieldType: FieldType.String,
    label: "Development Strategy",
    fieldKey: ["DevelopmentStrategy"]
  },
  LotStatus: {
    fieldType: FieldType.String,
    label: "Lot Status",
    fieldKey: ["LotStatus"]
  },
  CurrentLotMarketValueAmount: {
    fieldType: FieldType.Money,
    label: "Current Lot Market Value",
    fieldKey: ["CurrentLotMarketValueAmount"]
  },
  LotZonedParcelIdIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Is the lot already properly zoned and individually platted with a parcel ID #?",
    fieldKey: ["LotZonedParcelIdIndicator"],
    options: booleanOptions
  },
  BuildingPermitsIndicator: {
    fieldType: FieldType.Boolean,
    label: "Do you already have the required building permits?",
    fieldKey: ["BuildingPermitsIndicator"],
    options: booleanOptions
  },
  BuildingPermitsExpectedDate: {
    fieldType: FieldType.Date,
    label: "If no, when do you expect to receive them?",
    fieldKey: ["BuildingPermitsExpectedDate"]
  },
  LotUtilitiesIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Are utilities available at the lot? (at the street, ready to tap into, etc.)",
    fieldKey: ["LotUtilitiesIndicator"],
    options: booleanOptions
  },
  CreditReviewCommittee: {
    creditMemoDraftedDate: {
      fieldType: FieldType.Date,
      label: "Credit Memo Drafted",
      fieldKey: ["CreditReviewCommittee", "creditMemoDraftedDate"]
    },
    creditMemoApprovedDate: {
      fieldType: FieldType.Date,
      label: "Credit Memo Approved",
      fieldKey: ["CreditReviewCommittee", "creditMemoApprovedDate"]
    },
    internalSubmissionDate: {
      fieldType: FieldType.Date,
      label: "Internal CRC Submitted for Review",
      fieldKey: ["CreditReviewCommittee", "internalSubmissionDate"]
    },
    internalApprovalDate: {
      fieldType: FieldType.Date,
      label: "Internal CRC Approved",
      fieldKey: ["CreditReviewCommittee", "internalApprovalDate"]
    },
    externalSubmissionDate: {
      fieldType: FieldType.Date,
      label: "External CRC Submitted for Review",
      fieldKey: ["CreditReviewCommittee", "externalSubmissionDate"]
    },
    externalApprovalDate: {
      fieldType: FieldType.Date,
      label: "External CRC Approved",
      fieldKey: ["CreditReviewCommittee", "externalApprovalDate"]
    },
    lOIIssuedDate: {
      fieldType: FieldType.Date,
      label: "LOI Issued",
      fieldKey: ["CreditReviewCommittee", "lOIIssuedDate"]
    },
    lOIAcceptedDate: {
      fieldType: FieldType.Date,
      label: "LOI Accepted - Money Up",
      fieldKey: ["CreditReviewCommittee", "lOIAcceptedDate"]
    }
  },
  RecordedMortgageReceivedDate: {
    fieldKey: ["RecordedMortgageReceivedDate"],
    fieldType: FieldType.Date,
    label: "Recorded Mortgage Received Date"
  },
  TitlePolicyReceivedDate: {
    fieldKey: ["TitlePolicyReceivedDate"],
    fieldType: FieldType.Date,
    label: "Title Policy Received Date"
  },
  RecordedWarrantyDeedReceivedDate: {
    fieldKey: ["RecordedWarrantyDeedReceivedDate"],
    fieldType: FieldType.Date,
    label: "Recorded Warranty Deed Received Date"
  },
  Channel: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.Channel,
    changes: {
      options: createOptionsFromMap(limaChannelType)
    }
  }),
  LenderIdentifier: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.LenderIdentifier,
    changes: {
      options: createOptionsFromMap(limaLenderIdentifierType)
    }
  }),
  LoanTermPeriodMonthCount: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.LoanTermPeriodMonthCount,
    changes: {
      options: createOptionsFromMap(limaLoanTermPeriodMonthCount)
    }
  }),
  PaymentDueDay: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.PaymentDueDay,
    changes: {
      options: createOptionsFromMap(limaPaymentDueDay)
    }
  }),
  LoanPaymentType: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.LoanPaymentType,
    changes: {
      options: createOptionsFromMap(limaLoanPayment)
    }
  }),
  FloodInsurancePremiumPaymentType: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.FloodInsurancePremiumPaymentType,
    changes: {
      options: createOptionsFromMap(limaFloodInsurancePremiumPayment)
    }
  }),
  WarehouseBankType: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.WarehouseBankType,
    changes: {
      options: createOptionsFromMap(limaWarehouseBank)
    }
  })
};

const overriddenBaseSchemaDealSpecs: DeepPartial<typeof baseSchemaDealSpecs> = {
  aggregations: {
    LoanProductType: {
      override: modifyFromSpec({
        fieldSpecs: baseSchemaDealSpecs.aggregations.LoanProductType?.override,
        changes: {
          options: createOptionsFromMap(limaLoanProductType)
        }
      }),
      thirdParty: modifyFromSpec({
        fieldSpecs:
          baseSchemaDealSpecs.aggregations.LoanProductType?.thirdParty,
        changes: {
          options: createOptionsFromMap(limaLoanProductType)
        }
      })
    },
    LoanProgramType: {
      override: modifyFromSpec({
        fieldSpecs: baseSchemaDealSpecs.aggregations.LoanProgramType?.override,
        changes: {
          options: createOptionsFromMap(LoanProgramType)
        }
      }),
      thirdParty: modifyFromSpec({
        fieldSpecs:
          baseSchemaDealSpecs.aggregations.LoanProgramType?.thirdParty,
        changes: {
          options: createOptionsFromMap(LoanProgramType)
        }
      }),
      focused: modifyFromSpec({
        fieldSpecs: baseSchemaDealSpecs.aggregations.LoanProgramType?.focused,
        changes: {
          options: createOptionsFromMap(AggregationFocusType)
        }
      })
    },
    EstimatedClosingDate: {
      focused: modifyFromSpec({
        fieldSpecs:
          baseSchemaDealSpecs.aggregations.EstimatedClosingDate?.focused,
        changes: {
          options: createOptionsFromMap(AggregationFocusType)
        }
      })
    }
  },
  OperationsDepartment: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.OperationsDepartment,
    changes: {
      label: "Operations Department",
      options: createOptionsFromMap(limaDealOperationsDepartmentType)
    }
  }),
  LoanPackageTrackingNumber: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.LoanPackageTrackingNumber,
    changes: { label: "Physical Executed Loan Package Tracking Number" }
  }),
  LoanPackageNoteReceivedByLenderIndicator: modifyFromSpec({
    fieldSpecs: baseSchemaDealSpecs.LoanPackageNoteReceivedByLenderIndicator,
    changes: { label: "Note Received by Lima One?" }
  })
};

const pathsToOmitFromBase: BaseDealFieldsOmit = [
  "Channel",
  "LenderIdentifier",
  "LoanTermPeriodMonthCount",
  "PaymentDueDay",
  "LoanPaymentType",
  "FloodInsurancePremiumPaymentType",
  "WarehouseBankType",
  "InterestType",
  "MERSMin",
  "FundingDate",
  "PrincipalBalance",
  "LateFeeAmount",
  "InvestorInterestRate",
  "InterestRateBuydownPercent",
  "OriginationFeePercent",
  "NoteRatePercent",
  "PrepaymentPenaltyType",
  "PrepaymentPenaltyTerm",
  "LoanProductType",
  "FloorPercent",
  "CeilingPercent",
  "MarginPercent",
  "InterestReserveEscrowAmount",
  "ServicerLoanIdentifier",
  "InvestorLoanIdentifier",
  "ServicerAccountIdentifier",
  "SoldDate",
  "ServicingTransferDate",
  "ServicerIdentifier",
  "AssignmentStatus",
  "ExtendedMaturityDate",
  "PaidOffIndicator",
  "DefaultStatus",
  "FinalPrice",
  "InvestorIdentifier",
  "ShortTermRentalIndicator",
  "LockPeriod",
  "Exceptions.ExceptionApprovedBy"
];

const limaDealSpecs: FullLimaDealFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaDealSpecs,
    paths: pathsToOmitFromBase
  }),
  overriddenBaseSchemaDealSpecs,
  limaDealFieldSpecs,
  integrationSpecs.deal.WireInsuranceCertificate.FundingShield.spec
);

export const fullLimaDealSpecs = createSpecWithFieldMeta({
  spec: limaDealSpecs
});
