import { LabelValue } from "@elphi/types";
import { isArray, mergeWith, omit } from "lodash";
import { InputBuilderFieldSpecs } from "../../../components/form-builder/field-specs/fields.types";
import { DynamicSchema } from "../types";

type MergeReturnType<T extends object[]> = T extends [
  infer U extends object,
  ...infer R extends object[]
]
  ? U & MergeReturnType<R>
  : {};

export const omitFromSpec = <T extends object>(r: {
  obj: T;
  paths: string[];
}) => {
  return omit(r.obj, r.paths);
};

export const mergeSpecs = <T extends object[]>(
  ...objects: T
): MergeReturnType<T> => {
  return mergeWith({}, ...objects, (_, srcValue) => {
    if (isArray(srcValue)) {
      return srcValue;
    }
  });
};

export const modifyFromSpec = <T extends InputBuilderFieldSpecs<any>>(r: {
  fieldSpecs?: T;
  changes: Partial<InputBuilderFieldSpecs<any>>;
}) => {
  const { fieldSpecs, changes } = r;
  if (!fieldSpecs) {
    console.error("fieldSpecs is not defined");
    return {} as T;
  }
  return mergeSpecs({}, fieldSpecs, changes);
};

export const getMilestoneOptions = <T extends DynamicSchema["specs"]>(
  specs: T
): Array<LabelValue> => {
  const options =
    specs?.deal?.entitySpecs?.DealMetadata?.milestone?.options || [];

  return options;
};
