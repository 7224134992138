import { ElphiEnv } from "../../../common";
import { elphiPropertySchema } from "../../base/property.typebox";
import { limaPropertySchema } from "../../lima/property.typebox";
import { mofinPropertySchema } from "../../mofin/property.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type PropertyEnvToSchemaMap = EnvToSchemasMap;
export const propertySchemas: PropertyEnvToSchemaMap = {
  [ElphiEnv.Localhost]: elphiPropertySchema,
  [ElphiEnv.EdomonTest]: elphiPropertySchema,
  [ElphiEnv.BabarTest]: elphiPropertySchema,
  [ElphiEnv.Demo]: elphiPropertySchema,
  [ElphiEnv.MofinProd]: mofinPropertySchema,
  [ElphiEnv.MofinTest]: mofinPropertySchema,
  [ElphiEnv.LimaTest]: limaPropertySchema,
  [ElphiEnv.LimaPoc]: limaPropertySchema,
  [ElphiEnv.LimaProd]: limaPropertySchema,
  [ElphiEnv.ElamiaTest]: limaPropertySchema
};
