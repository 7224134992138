import {
  AggregationFocusType,
  ContactPointRoleType,
  CreditReportPullType,
  EntityType,
  FieldType,
  FixNFlipNBridgePlusTierType,
  PartyType,
  RealEstateOperationType,
  StateName,
  TaxpayerIdentifierType
} from "@elphi/types";
import {
  baseCitizenshipResidency,
  baseFixNFlipNBridgePlusTier,
  baseLOCStatus,
  basePartyMilestone
} from "@elphi/types/entities/base";
import {
  BaseSchemaCommonParty,
  BaseSchemaEntityParty,
  BaseSchemaIndividualParty
} from "@elphi/types/entities/base/party";
import { createCustomComponentConfig } from "../../../../../components/form-builder/FormBuilder";
import {
  addressFieldSpecs,
  nestedAddressFieldSpecs
} from "../../../../../components/form-builder/field-specs/address/address.fields";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import {
  EntityFormFieldSpecs,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { PartyGroupSearch } from "../../../../../components/organization-management/party-group/search/PartyGroupSearch";
import {
  booleanOptions,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";

export type BaseSchemaCommonPartyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<BaseSchemaCommonParty<PartyType, TaxpayerIdentifierType>>
>;

export type BaseSchemaIndividualPartyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<BaseSchemaIndividualParty>
>;

export type BaseSchemaEntityPartyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<BaseSchemaEntityParty>
>;

export const baseSchemaCommonPartyFieldSpecs: BaseSchemaCommonPartyFieldSpecs =
  {
    ...baseEntityFormFieldSpecs,
    ...nestedAddressFieldSpecs,
    aggregations: {
      TotalStatementQualifyingBalance: {
        calculated: {
          fieldKey: [
            "aggregations",
            "TotalStatementQualifyingBalance",
            "calculated"
          ],
          fieldType: FieldType.Money,
          label: "Total Statement Qualifying Balance"
        },
        override: {
          fieldKey: [
            "aggregations",
            "TotalStatementQualifyingBalance",
            "override"
          ],
          fieldType: FieldType.Money,
          label: "Total Statement Qualifying Balance"
        },
        focused: {
          fieldKey: [
            "aggregations",
            "TotalStatementQualifyingBalance",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          label: "Focus",
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BackgroundReportExpirationDate: {
        calculated: {
          fieldKey: [
            "aggregations",
            "BackgroundReportExpirationDate",
            "calculated"
          ],
          fieldType: FieldType.Date,
          label: "Background Report Expiration Date"
        },
        override: {
          fieldKey: [
            "aggregations",
            "BackgroundReportExpirationDate",
            "override"
          ],
          fieldType: FieldType.Date,
          label: "Background Report Expiration Date"
        },
        focused: {
          fieldKey: [
            "aggregations",
            "BackgroundReportExpirationDate",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          label: "Focus",
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LOCExpirationDate: {
        calculated: {
          fieldKey: ["aggregations", "LOCExpirationDate", "calculated"],
          fieldType: FieldType.Date,
          label: "LOC Expiration Date"
        },
        override: {
          fieldKey: ["aggregations", "LOCExpirationDate", "override"],
          fieldType: FieldType.Date,
          label: "LOC Expiration Date"
        },
        focused: {
          fieldKey: ["aggregations", "LOCExpirationDate", "focused"],
          fieldType: FieldType.SingleSelect,
          label: "Focus",
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      }
    },
    GroupId: {
      fieldKey: ["GroupId"],
      fieldType: FieldType.SingleSelect,
      label: "Party group",
      componentOverride: createCustomComponentConfig({
        component: PartyGroupSearch,
        props: {
          wrapperStyle: { padding: 0 },
          shouldFilterDisabled: true
        }
      })
    },
    PartyType: {
      fieldKey: ["PartyType"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(PartyType),
      label: "Party Type"
    },
    TaxpayerIdentifierType: {
      fieldKey: ["TaxpayerIdentifierType"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(TaxpayerIdentifierType),
      label: "Taxpayer Identifier Type"
    },
    TaxpayerIdentifierValue: {
      fieldKey: ["TaxpayerIdentifierValue"],
      fieldType: FieldType.String,
      label: "SSN/TIN/Business EIN"
    },
    LiquidAssetTotalAmount: {
      fieldKey: ["LiquidAssetTotalAmount"],
      fieldType: FieldType.Money,
      label: "Liquid Asset Total Amount"
    },
    OperationsDepartment: {
      fieldKey: ["OperationsDepartment"],
      fieldType: FieldType.SingleSelect,
      label: "Branch"
    },
    BackgroundReportDate: {
      fieldType: FieldType.Date,
      label: "Background Report Date",
      fieldKey: ["BackgroundReportDate"]
    },

    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Contact Point Email",
      fieldKey: ["ContactPointEmailValue"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Contact Point Telephone",
      fieldKey: ["ContactPointTelephoneValue"]
    },
    CitizenshipResidency: {
      fieldType: FieldType.SingleSelect,
      label: "Citizenship Type",
      options: createOptionsFromMap(baseCitizenshipResidency),
      fieldKey: ["CitizenshipResidency"]
    },
    CreditScore: {
      fieldKey: ["CreditScore"],
      fieldType: FieldType.CreditScore,
      label: "Credit Score"
    },
    NumberOfRehabProjects: {
      fieldType: FieldType.Integer,
      label: "Number of Rehab Projects",
      fieldKey: ["NumberOfRehabProjects"]
    },
    NumberOfTransactions: {
      fieldType: FieldType.Integer,
      label: "Number of Transactions",
      fieldKey: ["NumberOfTransactions"]
    },
    CreditReportDate: {
      fieldType: FieldType.Date,
      label: "Credit Report Date",
      fieldKey: ["CreditReportDate"]
    }
  };

export const baseSchemaIndividualPartyFieldSpecs: BaseSchemaIndividualPartyFieldSpecs =
  {
    ...baseSchemaCommonPartyFieldSpecs,
    FirstName: {
      fieldType: FieldType.String,
      label: "First Name",
      fieldKey: ["FirstName"]
    },
    MiddleName: {
      fieldType: FieldType.String,
      label: "Middle Name",
      fieldKey: ["MiddleName"]
    },
    LastName: {
      fieldType: FieldType.String,
      label: "Last Name",
      fieldKey: ["LastName"]
    },
    BirthDate: {
      fieldType: FieldType.Date,
      label: "Birthdate",
      fieldKey: ["BirthDate"]
    },
    GovernmentIssuedIDExpirationDate: {
      fieldType: FieldType.Date,
      label: "Government Issued ID Expiration Date",
      fieldKey: ["GovernmentIssuedIDExpirationDate"]
    },
    CitizenshipCountry: {
      fieldType: FieldType.String,
      label: "Citizenship Country",
      fieldKey: ["CitizenshipCountry"]
    },
    ContactPointRoleType: {
      fieldType: FieldType.SingleSelect,
      label: "Contact Point Telephone Type",
      options: createOptionsFromMap(ContactPointRoleType),
      fieldKey: ["ContactPointRoleType"]
    },
    CreditReportNotes: {
      fieldType: FieldType.RichText,
      label: "Credit Report Notes",
      fieldKey: ["CreditReportNotes"]
    },
    CreditReportDate: {
      fieldType: FieldType.Date,
      label: "Credit Report Date",
      fieldKey: ["CreditReportDate"]
    },
    CreditReportPullType: {
      fieldType: FieldType.SingleSelect,
      label: "Hard/Soft Pull",
      options: createOptionsFromMap(CreditReportPullType),
      fieldKey: ["CreditReportPullType"]
    },
    FixNFlipNBridgePlusTier: {
      fieldType: FieldType.SingleSelect,
      label: "Experience Tier",
      fieldKey: ["FixNFlipNBridgePlusTier"],
      options: createOptionsFromMap(FixNFlipNBridgePlusTierType)
    },
    LOCStatusType: {
      fieldType: FieldType.SingleSelect,
      label: "Party Milestone",
      options: createOptionsFromMap(baseLOCStatus),
      fieldKey: ["LOCStatusType"]
    },
    LoanAmountInProcess: {
      fieldType: FieldType.Money,
      label: "Loan Amount in Process",
      fieldKey: ["LoanAmountInProcess"]
    },
    NewConstructionEligibilityIndicator: {
      fieldType: FieldType.Boolean,
      label: "New Construction Eligible?",
      options: booleanOptions,
      fieldKey: ["NewConstructionEligibilityIndicator"]
    },
    RemainingLOCAvailable: {
      fieldType: FieldType.Money,
      label: "Remaining LOC Amount",
      fieldKey: ["RemainingLOCAvailable"]
    },
    TotalApprovedLOC: {
      fieldType: FieldType.Money,
      label: "Approved LOC",
      fieldKey: ["TotalApprovedLOC"]
    },
    UnpaidPrincipalBalance: {
      fieldType: FieldType.Money,
      label: "Unpaid Principal Balance",
      fieldKey: ["UnpaidPrincipalBalance"]
    },
    ExperienceNotes: {
      fieldType: FieldType.RichText,
      label: "Experience Notes",
      fieldKey: ["ExperienceNotes"]
    },
    FlipsAverageProjectDuration: {
      fieldType: FieldType.Decimal,
      label: "Flips Average Project Duration (Months)",
      fieldKey: ["FlipsAverageProjectDuration"]
    },
    FlipsAverageSalePrice: {
      fieldType: FieldType.Money,
      label: "Flips Average Sale Price",
      fieldKey: ["FlipsAverageSalePrice"]
    },
    FlipsCumulativeSalePrice: {
      fieldType: FieldType.Money,
      label: "Flips Cumulative Sale Price",
      fieldKey: ["FlipsCumulativeSalePrice"]
    },
    NewBuildsAverageProjectDuration: {
      fieldType: FieldType.Decimal,
      label: "New Builds Average Project Duration (Months)",
      fieldKey: ["NewBuildsAverageProjectDuration"]
    },
    NewBuildsAverageSalePrice: {
      fieldType: FieldType.Money,
      label: "New Builds Average Sale Price",
      fieldKey: ["NewBuildsAverageSalePrice"]
    },
    NewBuildsCumulativeSalePrice: {
      fieldType: FieldType.Money,
      label: "New Builds Cumulative Sale Price",
      fieldKey: ["NewBuildsCumulativeSalePrice"]
    },
    NumberOfFlips: {
      fieldType: FieldType.Number,
      label: "Number Of Flips",
      fieldKey: ["NumberOfFlips"]
    },
    NumberOfNewBuilds: {
      fieldType: FieldType.Number,
      label: "Number of New Builds",
      fieldKey: ["NumberOfNewBuilds"]
    },
    CompletedExits: {
      fieldType: FieldType.Number,
      label: "Completed Exits",
      fieldKey: ["CompletedExits"]
    },
    NumberOfTransactions: {
      fieldType: FieldType.Number,
      label: "Number of Transactions in the Last 36 Months",
      fieldKey: ["NumberOfTransactions"]
    },
    TransactionsAverageProjectDuration: {
      fieldType: FieldType.Decimal,
      label: "Transactions Average Project Duration (Months)",
      fieldKey: ["TransactionsAverageProjectDuration"]
    },
    TransactionsAverageSalePrice: {
      fieldType: FieldType.Money,
      label: "Transactions Average Sale Price",
      fieldKey: ["TransactionsAverageSalePrice"]
    },
    TransactionsCumulativeSalePrice: {
      fieldType: FieldType.Money,
      label: "Transactions Cumulative Sale Price",
      fieldKey: ["TransactionsCumulativeSalePrice"]
    },
    USCitizenshipIndicator: {
      fieldType: FieldType.Boolean,
      label: "US Citizen?",
      fieldKey: ["USCitizenshipIndicator"],
      options: booleanOptions
    },
    StatedCashOnHandAmount: {
      fieldKey: ["StatedCashOnHandAmount"],
      fieldType: FieldType.Money,
      label: "Stated Cash On Hand"
    },
    EstimatedCreditScore: {
      fieldKey: ["EstimatedCreditScore"],
      fieldType: FieldType.CreditScore,
      label: "Estimated Credit Score"
    },
    ConvictionIndicator: {
      fieldType: FieldType.Boolean,
      label:
        "Convicted of, plead guilty or no contest to, or currently accused of a felony, or any crime involving fraud",
      fieldKey: ["ConvictionIndicator"],
      options: booleanOptions
    },
    OutstandingLawsuitsIndicator: {
      fieldType: FieldType.Boolean,
      label: "Party to any outstanding lawsuits?",
      fieldKey: ["OutstandingLawsuitsIndicator"],
      options: booleanOptions
    },
    OutstandingJudgmentsIndicator: {
      fieldType: FieldType.Boolean,
      label: "Have any outstanding judgments?",
      fieldKey: ["OutstandingJudgmentsIndicator"],
      options: booleanOptions
    },
    BankruptcyIndicator: {
      fieldType: FieldType.Boolean,
      label: "Declared bankruptcy in the past 4 years?",
      fieldKey: ["BankruptcyIndicator"],
      options: booleanOptions
    },
    ForeclosureIndicator: {
      fieldType: FieldType.Boolean,
      label: "Had a foreclosure or given title or deed in lieu of foreclosure?",
      fieldKey: ["ForeclosureIndicator"],
      options: booleanOptions
    },
    MortgageDelinquentIndicator: {
      fieldType: FieldType.Boolean,
      label:
        "Presently delinquent on any mortgage loan or other financial obligation?",
      fieldKey: ["MortgageDelinquentIndicator"],
      options: booleanOptions
    }
  };

export const baseSchemaEntityPartyFieldSpecs: BaseSchemaEntityPartyFieldSpecs =
  {
    ...baseSchemaCommonPartyFieldSpecs,
    TotalOutstandingLongTermLoanCount: {
      fieldKey: ["TotalOutstandingLongTermLoanCount"],
      fieldType: FieldType.Number,
      label: "TotalOutstandingLongTermLoanCount"
    },
    TotalOutstandingLongTermPrincipalAmount: {
      fieldKey: ["TotalOutstandingLongTermPrincipalAmount"],
      fieldType: FieldType.Number,
      label: "TotalOutstandingLongTermPrincipalAmount"
    },
    BusinessIncorporationStateName: {
      fieldKey: ["BusinessIncorporationStateName"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(StateName),
      label: "Business Incorporation State Name"
    },
    BusinessIncorporationStateCode: {
      fieldKey: ["BusinessIncorporationStateCode"],
      fieldType: FieldType.SingleSelect,
      options: addressFieldSpecs.StateCode.options,
      label: "Business Incorporation State Code"
    },
    BusinessRegistrationStates: {
      fieldKey: ["BusinessRegistrationStates"],
      fieldType: FieldType.MultiSelect,
      options: addressFieldSpecs.StateCode.options,
      label: "Registered to do business in which states?"
    },
    EntityType: {
      fieldKey: ["EntityType"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(EntityType),
      label: "Entity Type"
    },
    FixAndFlipBridgePlusTier: {
      fieldKey: ["FixAndFlipBridgePlusTier"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(baseFixNFlipNBridgePlusTier),
      label: "Experience Tier"
    },
    FullName: {
      fieldKey: ["FullName"],
      fieldType: FieldType.String,
      label: "Entity Name"
    },
    NewConstructionEligibilityIndicator: {
      fieldKey: ["NewConstructionEligibilityIndicator"],
      fieldType: FieldType.Boolean,
      options: booleanOptions,
      label: "New Construction Eligible?"
    },
    Notes: {
      fieldKey: ["Notes"],
      fieldType: FieldType.RichText,
      label: "Notes"
    },
    EvidenceOfGoodStandingExpirationDate: {
      fieldKey: ["EvidenceOfGoodStandingExpirationDate"],
      fieldType: FieldType.Date,
      label: "Evidence of Good Standing Expiration Date"
    },
    RealEstateOperationType: {
      fieldKey: ["RealEstateOperationType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromMap(RealEstateOperationType),
      label: "Real Estate Operation Type"
    },
    RemainingLineOfCreditAmount: {
      fieldKey: ["RemainingLineOfCreditAmount"],
      fieldType: FieldType.Money,
      label: "Remaining LOC Amount"
    },
    TotalFixAndFlipNewConstructionSoldPastThreeYearsCount: {
      fieldKey: ["TotalFixAndFlipNewConstructionSoldPastThreeYearsCount"],
      fieldType: FieldType.Number,
      label: "Total Fix&Flip/NC Sold in Past 3 Years"
    },
    PartyMilestone: {
      fieldKey: ["PartyMilestone"],
      fieldType: FieldType.SingleSelect,
      label: "Party Milestone",
      options: createOptionsFromMap(basePartyMilestone)
    },
    RepresentativeCreditScore: {
      fieldKey: ["RepresentativeCreditScore"],
      fieldType: FieldType.CreditScore,
      label: "Representative Credit Score"
    },
    LiquidAssetTotalAmount: {
      fieldKey: ["LiquidAssetTotalAmount"],
      fieldType: FieldType.Money,
      label: "Entity Liquid Asset Total Amount"
    },
    AuthorizedContacts: {
      fieldType: FieldType.String,
      label: "Authorized Contacts",
      fieldKey: ["AuthorizedContacts"]
    }
  };

export const fullBaseSchemaEntityPartySpecs = createSpecWithFieldMeta({
  spec: baseSchemaEntityPartyFieldSpecs
});
export const fullBaseSchemaIndividualPartySpecs = createSpecWithFieldMeta({
  spec: baseSchemaIndividualPartyFieldSpecs
});
