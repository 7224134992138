import { Deal, LoanProgramType, ThirdPartyField } from "@elphi/types";
import { InputBuilderSpecs, Section } from "../../form-builder/FormBuilder";
import { getFocusedData } from "@elphi/utils";
type WithLoanProgramType = {
  aggregations: { LoanProgramType?: ThirdPartyField };
};

export const loanProgramTypeIsNotEmpty = (loanProgramType?: LoanProgramType) =>
  !!loanProgramType;

export const hideFixNFlip = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![
          LoanProgramType.FixNFlip,
          LoanProgramType.NewConstruction,
          LoanProgramType.BridgePlus
        ].includes(loanProgramType);
};

export const hideFixNFlipNoBridge = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.FixNFlip, LoanProgramType.NewConstruction].includes(
          loanProgramType
        );
};

const hasMatchingLoanProgramType = <T extends WithLoanProgramType>(
  loanProgramTypesList: LoanProgramType[],
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : !!loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        loanProgramTypesList.includes(loanProgramType);
};

export const isNewConstruction = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  return hasMatchingLoanProgramType([LoanProgramType.NewConstruction], state);
};

export const isFixNFlip = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  return hasMatchingLoanProgramType([LoanProgramType.FixNFlip], state);
};

export const isRental30Premier = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  return hasMatchingLoanProgramType([LoanProgramType.Rental30Premier], state);
};

export const isEmptyLoanProgramType = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideFixNFlipOrEmpty = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return hideFixNFlip(state) || !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideRental30 = <T extends WithLoanProgramType>(
  state?: Partial<T> | Deal
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.Rental30, LoanProgramType.Rental30Premier].includes(
          loanProgramType
        );
};

export const hideRental30OrEmpty = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return hideRental30(state) || !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideBridgePlus = <T extends WithLoanProgramType>(
  state?: Partial<T>
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.BridgePlus].includes(loanProgramType);
};

export const addGeneralSpecsToSections = (r: {
  sections: Section[];
  specs: Partial<InputBuilderSpecs>;
}): Section[] => {
  r.sections.forEach((s) => {
    s.inputs.forEach((i) => (i.isReadOnly = true));
  });
  return r.sections;
};
