import { MofinEntityParty } from "@elphi/types/entities/mofin/party";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";

import { operationsDepartment } from "../../../base/inputs/deal.inputs";
import {
  authorizedContacts,
  backgroundReportDate,
  backgroundReportExpirationDate,
  businessIncorporationStateCode,
  businessIncorporationStateName,
  businessRegistrationStates,
  citizenshipResidency,
  creditReportDate,
  entityFullName,
  entityNotes,
  entityType,
  evidenceOfGoodStandingExpirationDate,
  fixAndFlipBridgePlusTier,
  newConstructionEligibilityIndicator,
  numberOfRehabProjects,
  numberOfTransactions,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  realEstateOperationType,
  representativeCreditScore
} from "../../../base/inputs/party.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import { mortgageLatePaymentsIndicator } from "../../inputs/party.inputs";
import { mofinSpecs } from "../../spec-files/mofinFieldSpecs";
import { MofinEntityPartySections } from "../../types";

const entityInfoSection = (r: {
  state: Partial<MofinEntityParty>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Entity Info",
    inputs: [
      entityType,
      entityFullName,
      partyContactPointEmailValue,
      partyContactPointTelephoneValue,
      representativeCreditScore,
      creditReportDate,
      businessIncorporationStateName,
      businessIncorporationStateCode,
      businessRegistrationStates,
      fixAndFlipBridgePlusTier,
      newConstructionEligibilityIndicator,
      realEstateOperationType,
      operationsDepartment,
      entityNotes,
      backgroundReportDate,
      backgroundReportExpirationDate,
      evidenceOfGoodStandingExpirationDate,
      citizenshipResidency,
      mortgageLatePaymentsIndicator,
      numberOfRehabProjects,
      numberOfTransactions,
      authorizedContacts
    ],
    fieldSpecs: mofinSpecs.party.entitySpecs
  });

export const entityPartySectionBuilders: MofinEntityPartySections = {
  entityInfoSection
};
